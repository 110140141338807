<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.goodsStatusType"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="出售中"
                    name="1"
                />
                <el-tab-pane
                    label="已售罄"
                    name="2"
                />
                <el-tab-pane
                    label="仓库中"
                    name="3"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="goodsSn"
                    label="商品货号"
                >
                    <el-input
                        v-model="queryFormModel.goodsSn"
                        placeholder="请输入商品货号"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="skuSn"
                    label="sku货号"
                >
                    <el-input
                        v-model="queryFormModel.skuSn"
                        placeholder="请输入sku货号"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onSetBatchSkuCost"
                    >
                        批量操作
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    prop="goodsSn"
                    label="商品货号"
                    width="150"
                />
                <el-table-column
                    prop="goodsName"
                    label="商品名称"
                    min-width="50"
                />
                <el-table-column
                    prop="skuSn"
                    label="sku货号"
                    width="150"
                />
                <el-table-column
                    prop="specDesc"
                    label="商品规格"
                    min-width="100"
                />
                <el-table-column
                    prop="costPrice"
                    label="商品成本"
                    width="100"
                />
                <el-table-column
                    label="运费成本"
                    min-width="100"
                >
                    <template slot-scope="scope">
                        <div
                            v-for="(item,index) in scope.row.freightCostList"
                            :key="index"
                        >
                            {{ item }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="90"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableEdit(scope.row)"
                        >
                            成本设置
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 弹框 -->
        <!-- 新增/编辑 -->
        <el-dialog
            title="成本设置"
            center
            width="1400px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
                label-position="left"
            >
                <el-form-item
                    prop="costPrice"
                    label="商品成本"
                    label-width="6em"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="addDialog.formModel.costPrice"
                    />
                </el-form-item>
                <el-form-item
                    label="运费成本"
                    label-width="6em"
                >
                    <div
                        style="margin-bottom: 20px;"
                    >
                        <el-button
                            type="success"
                            size="small"
                            @click="onSelectAll"
                        >
                            全选
                        </el-button>
                        <el-button
                            type="success"
                            size="small"
                            @click="onInverseSelection"
                        >
                            反选
                        </el-button>
                        <el-input-number
                            style="margin-left: 14px"
                            controls-position="right"
                            :min="0"
                            :precision="2"
                            v-model.number="addDialog.batchPrice"
                        />
                        <el-button
                            style="margin-left: 10px"
                            type="success"
                            size="small"
                            @click="onSetBatchPrice"
                        >
                            批量设置
                        </el-button>
                    </div>
                    <el-checkbox-group
                        v-model="addDialog.selectRegion"
                    >
                        <el-checkbox
                            style="width: 25%;margin-left:0"
                            v-for="item in addDialog.regionList"
                            :label="item.regionRange"
                            :key="item.regionRange"
                        >
                            <div @click.stop.prevent="()=>{}">
                                <el-form-item
                                    :label="item.regionRangeName"
                                    label-width="8em"
                                    style="margin-bottom: 3px;"
                                >
                                    <el-input-number
                                        @change="changeSkuFreight"
                                        controls-position="right"
                                        :min="0"
                                        :precision="2"
                                        v-model.number="item.freightCost"
                                    />
                                </el-form-item>
                            </div>
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item
                    label-width="6em"
                >
                    <div
                        v-for="(item,index) in addDialog.costList"
                        :key="index"
                    >
                        {{ item.freightCost }} -- {{ item.list.join(',') }}
                    </div>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'SkuCost',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                goodsSn: '', // 商品货号
                goodsName: '', // 商品名称
                skuSn: '', // 商品名称
                goodsStatusType: '1', // 商品状态类型
            },
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    ids: '',
                    costPrice: '',
                    skuFreightJson: '',
                },
                // 表单校验规则
                formRules: {},
                // 地区列表
                regionList: [],
                selectRegion: [],
                batchPrice: '',
                costList: [],
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Gd.SkuFreightCost.skuCostList(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onSetBatchSkuCost() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            if (ids.length === 0) {
                this.$message({
                    message: '请选择数据',
                    type: 'error',
                });
                return;
            }
            this.onSetSkuCost(ids);
        },
        onTableEdit(row) {
            const ids = [];
            ids.push(row.id);
            this.onSetSkuCost(ids);
        },
        onSetSkuCost(ids) {
            this.addDialog.formModel.ids = ids.join(',');
            if (ids.length === 1) {
                //单个编辑
                this.$api.Gd.SkuFreightCost.getSkuCostDetail({ id: ids[0] }).then(json => {
                    const res = json.data.data;
                    this.addDialog.formModel.costPrice = res.costPrice;
                    this.addDialog.regionList = res.skuFreightCostList;
                    this.changeSkuFreight();
                });
            } else {
                //批量操作
                //初始化地区数据
                this.$api.Rs.Region.data({ pId: 2 }).then(json => {
                    const res = json.data.data;
                    res.forEach(item => {
                        item.regionRange = item.id;
                        item.regionRangeName = item.name;
                        item.id = null;
                        item.freightCost = 0;
                    });
                    this.addDialog.regionList = res;
                    this.changeSkuFreight();
                });
            }
            this.addDialog.batchPrice = null;
            this.addDialog.selectRegion = [];
            this.addDialog.isVisible = true;
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Gd.SkuFreightCost.save({
                    ids: this.addDialog.formModel.ids,
                    costPrice: this.addDialog.formModel.costPrice,
                    skuFreightCostJson: JSON.stringify(this.addDialog.regionList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
        onSelectAll() {
            //全选
            const selectIds = [];
            this.addDialog.regionList.forEach(item => {
                selectIds.push(item.regionRange);
            });
            this.addDialog.selectRegion = selectIds;
        },
        onInverseSelection() {
            //反选
            const selectIds = [];
            this.addDialog.regionList.forEach(item => {
                if (!this.addDialog.selectRegion.includes(item.regionRange)) {
                    selectIds.push(item.regionRange);
                }
            });
            this.addDialog.selectRegion = selectIds;
        },
        onSetBatchPrice() {
            this.addDialog.regionList.forEach(item => {
                if (this.addDialog.selectRegion.includes(item.regionRange)) {
                    item.freightCost = this.addDialog.batchPrice;
                }
            });
            this.changeSkuFreight();
        },
        changeSkuFreight() {
            const costMap = {};
            const costList = [];
            this.addDialog.regionList.forEach(item => {
                if (item.freightCost > 0) {
                    if (!costMap[item.freightCost]) {
                        const list = [];
                        costMap[item.freightCost] = list;
                        const freightItem = {
                            freightCost: item.freightCost,
                            list: list,
                        };
                        costList.push(freightItem);
                    }
                    costMap[item.freightCost].push(item.regionRangeName);
                }
            });
            this.addDialog.costList = costList;
        },
    },
};
</script>

<style lang="scss">
</style>
