<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.goodsStatusType"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="出售中"
                    name="1"
                />
                <el-tab-pane
                    label="已售罄"
                    name="2"
                />
                <el-tab-pane
                    label="仓库中"
                    name="3"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="goodsSn"
                    label="商品货号"
                >
                    <el-input
                        v-model="queryFormModel.goodsSn"
                        placeholder="请输入商品货号"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsCatId"
                    label="商品类目"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="queryFormModel.goodsCatId"
                    />
                </el-form-item>
                <el-form-item
                    prop="supplierId"
                    label="供应商"
                >
                    <el-select
                        v-model="queryFormModel.supplierId"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in supplierList"
                            :key="item.id"
                            :label="item.supplierName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        v-if="type === 1"
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/gd/goods/addGoods')"
                    >
                        新增
                    </el-button>
                    <el-button
                        v-if="type === 2"
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/cgd/goods/addCustomGoods')"
                    >
                        新增
                    </el-button>
                    <el-button
                        v-if="type === 3"
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="$router.push('/gd/goods/addBondedGoods')"
                    >
                        新增
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="id"
                    label="ID"
                    width="50"
                />
                <el-table-column
                    label="商品图片"
                    width="120"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.mainImageUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="goodsName"
                    label="商品名称"
                    min-width="100"
                />
                <el-table-column
                    prop="goodsSn"
                    label="商品货号"
                    min-width="50"
                />
                <el-table-column
                    prop="goodsCatName"
                    label="商品类目"
                    min-width="50"
                />
                <el-table-column
                    prop="supplierName"
                    label="供应商名称"
                    min-width="50"
                />
                <el-table-column
                    prop="price"
                    label="销售价"
                    min-width="50"
                />
                <el-table-column
                    prop="marketPrice"
                    label="市场价"
                    min-width="50"
                />
                <el-table-column
                    prop="stock"
                    label="库存"
                    min-width="50"
                />
                <el-table-column
                    prop="goodsStatusName"
                    label="商品状态"
                    min-width="50"
                />
                <el-table-column
                    prop="orderNum"
                    label="排序"
                    width="200"
                >
                    <template slot-scope="scope">
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :max="999999"
                            v-model.number="scope.row.orderNum"
                            @change="onOrderNumInput(scope.row)"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="180"
                >
                    <template slot-scope="scope">
                        <el-button
                            v-if="type === 1"
                            type="text"
                            size="small"
                            @click="$router.push(`/gd/goods/addGoods/${scope.row.id}`)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            v-if="type === 2"
                            type="text"
                            size="small"
                            @click="$router.push(`/cgd/goods/addCustomGoods/${scope.row.id}`)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            v-if="type === 3"
                            type="text"
                            size="small"
                            @click="$router.push(`/gd/goods/addBondedGoods/${scope.row.id}`)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            v-if="type === 1 && userData.shop.shopStoreType !== 99"
                            type="text"
                            size="small"
                            @click="editGoodsExtendInfo(scope.row.id)"
                        >
                            扩展信息
                        </el-button>
                        <!--<el-button
                            type="text"
                            size="small"
                            @click="$router.push('/gd/goods/goods/skuMemberPrice/' + scope.row.id)"
                        >会员价
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/gd/goods/goods/skuDistributionPrice/' + scope.row.id)"
                        >分销价
                        </el-button>-->
                        <el-button
                            type="text"
                            size="small"
                            v-if="scope.row.goodsStatus == 1 || scope.row.goodsStatus == 4"
                            @click="onSaleGoods(scope.row)"
                        >
                            上架
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            v-if="scope.row.goodsStatus == 3 || (scope.row.goodsStatus == 4 && scope.row.offShelvesReason == 2)"
                            @click="offSaleGoods(scope.row)"
                        >
                            下架
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onGoodsForbidSell(scope.row)"
                            v-if="userData.shop.shopStoreType !== 99"
                        >
                            禁售分销商
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <el-dialog
            title="扩展信息"
            center
            width="900px"
            :visible.sync="editDialog.isVisible"
            @closed="onEditDialogCancel"
        >
            <el-form
                ref="editForm"
                size="small"
                :model="editDialog.formModel"
                :rules="editDialog.formRules"
            >
                <el-tabs
                    type="card"
                    v-model="editTab"
                >
                    <el-tab-pane
                        label="分享文案"
                        name="1"
                    >
                        <el-form-item
                            label="文案列表"
                            label-width="6em"
                        >
                            <el-table
                                stripe
                                border
                                size="small"
                                :data="editDialog.goodsShareCopyList"
                                style="width: 100%"
                            >
                                <el-table-column
                                    label="文案名称"
                                    width="150"
                                >
                                    <template slot-scope="scope">
                                        <el-input
                                            v-model="scope.row.copyName"
                                            auto-complete="off"
                                        />
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    label="文案内容"
                                    min-width="150"
                                >
                                    <template slot-scope="scope">
                                        <el-input
                                            type="textarea"
                                            v-model="scope.row.copyContent"
                                            auto-complete="off"
                                        />
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-tab-pane>
                    <el-tab-pane
                        label="商品素材"
                        name="2"
                    >
                        <el-tabs
                            tab-position="left"
                            v-model="goodsStyleTab"
                        >
                            <el-tab-pane
                                v-for="item in editDialog.goodsMultiStyleList"
                                :key="item.goodsStyleId"
                                :label="item.styleName"
                                :name="item.styleName"
                            >
                                <el-form-item
                                    label-width="6em"
                                    prop="goodsName"
                                    label="商品名称"
                                >
                                    <el-input
                                        placeholder="请输入"
                                        v-model="item.goodsName"
                                    />
                                </el-form-item>
                                <el-form-item
                                    label-width="6em"
                                    prop="goodsSubtitle"
                                    label="商品副标题"
                                >
                                    <el-input
                                        placeholder="请输入"
                                        v-model="item.goodsSubtitle"
                                    />
                                </el-form-item>
                                <el-form-item
                                    label-width="6em"
                                    prop="goodsImage"
                                    label="商品列表图"
                                >
                                    <upload
                                        draggable
                                        action="/rs/attachment/uploadGoodsImage"
                                        :limit="12"
                                        tips="建议尺寸：800*800像素,最多上传12张图片"
                                        v-model="item.goodsImage"
                                    />
                                </el-form-item>
                                <el-form-item
                                    label-width="6em"
                                    prop="goodsDetailImage"
                                    label="商品详情图"
                                >
                                    <upload
                                        draggable
                                        action="/rs/attachment/uploadGoodsImage"
                                        :limit="12"
                                        tips="建议尺寸：800*(不限)像素,最多上传12张图片"
                                        v-model="item.goodsDetailImage"
                                    />
                                </el-form-item>
                            </el-tab-pane>
                        </el-tabs>
                    </el-tab-pane>
                </el-tabs>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onEditDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onEditDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <el-dialog
            title="商品上架"
            center
            width="1200px"
            :visible.sync="onSaleDialog.isVisible"
            @closed="onSaleDialogCancel"
        >
            <el-form
                ref="onSaleForm"
                size="small"
                :model="onSaleDialog.formModel"
                :rules="onSaleDialog.formRules"
            >
                <el-form-item>
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="onSaleDialog.orderDetailList"
                        style="width: 100%"
                        :span-method="spanMethod"
                    >
                        <el-table-column
                            v-for="item in onSaleDialog.goodsSpecList"
                            :label="item.pn"
                            :key="item.id"
                            min-width="150"
                        >
                            <template slot-scope="scope">
                                {{ scope.row.specValueMap[item.pn] }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="SKU编码"
                            min-width="100"
                            prop="skuSn"
                        />
                        <el-table-column
                            label="价格"
                            width="150"
                        >
                            <template slot-scope="scope">
                                <el-input-number
                                    controls-position="right"
                                    :min="0"
                                    :precision="2"
                                    v-model.number="scope.row.price"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="库存"
                            width="150"
                        >
                            <template slot-scope="scope">
                                <el-input-number
                                    controls-position="right"
                                    :min="0"
                                    v-model.number="scope.row.stock"
                                />
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="cell">
                        <span class="batch">批量设置: </span>
                        <template v-for="(item,index) in formField">
                            <!-- TODO vue/no-use-v-if-with-v-for 这不是一个好的解决方案，应该是处理 formField 数据时就过滤掉-->
                            <el-button
                                v-if="!item.hide"
                                :key="index"
                                @click="onBatch(item)"
                            >
                                {{ item.name }}
                            </el-button>
                        </template>
                    </div>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSaleDialogConfirm"
                >
                    上 架
                </el-button>
                <el-button
                    size="small"
                    @click="onSaleDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <!--分销商禁售-->
        <el-dialog
            title="分销商禁售"
            center
            width="1200px"
            :visible.sync="goodsForbidSellDialog.isVisible"
            @closed="onGoodsForbidSellCancel"
        >
            <!-- 内容 -->
            <div class="body">
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAddSelect()"
                    >
                        选择分销商
                    </el-button>
                </el-button-group>
                <el-table
                    stripe
                    border
                    size="small"
                    :data="goodsForbidSellDialog.tableData"
                    style="width: 100%"
                >
                    <el-table-column
                        prop="distributorLevelName"
                        label="分销等级"
                        min-width="100"
                    />
                    <el-table-column
                        prop="distributorName"
                        label="分销商名称"
                        min-width="100"
                    />
                    <el-table-column
                        prop="distributorTel"
                        label="分销商电话"
                        min-width="100"
                    />
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="45"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                size="small"
                                @click="onDeleteSelect(scope.row)"
                            >
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="goodsForbidSellDialog.pagination"
                        @input="onGoodsForbidSellDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    size="small"
                    @click="onGoodsForbidSellCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <!--选择会员-->
        <el-dialog
            title="选择会员"
            center
            width="1200px"
            :visible.sync="selectDialog.isVisible"
            @closed="onSelectCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectDialog.queryFormModel"
            >
                <el-form-item
                    prop="distributorName"
                    label="分销商姓名"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.distributorName"
                        placeholder="请输入分销商姓名"
                    />
                </el-form-item>
                <el-form-item
                    prop="distributorTel"
                    label="分销商电话"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.distributorTel"
                        placeholder="请输入分销商电话"
                    />
                </el-form-item>
                <el-form-item
                    prop="distributorLevelId"
                    label="分销商等级"
                >
                    <el-select
                        v-model="selectDialog.queryFormModel.distributorLevelId"
                        placeholder="请选择"
                        clearable
                    >
                        <el-option
                            v-for="item in distributorLevelList"
                            :key="item.id"
                            :label="item.levelName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="id"
                    ref="selectDialogTable"
                    @selection-change="onSelectDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        width="36"
                    />
                    <el-table-column
                        prop="distributorLevelName"
                        label="分销等级"
                        min-width="100"
                    />
                    <el-table-column
                        prop="distributorName"
                        label="分销商名称"
                        min-width="100"
                    />
                    <el-table-column
                        prop="distributorTel"
                        label="分销商电话"
                        min-width="100"
                    />
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectDialog.pagination"
                        @input="onSelectDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSelectConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onSelectCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'Goods',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            type: '',
            queryFormModel: {
                goodsName: '', // 商品名称
                goodsSn: '', // 商品货号
                goodsCatId: '', // 商品类目
                supplierId: '', // 供应商
                goodsStatusType: '1', // 商品状态类型
                isPrivateCustom: '',
                goodsSendModeList: '',
            },
            // 表格数据
            tableData: [],
            selectApi: this.$api.Gd.GoodsCat,
            supplierList: [],
            // 文案编辑
            editDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    goodsShareCopy: '',
                },
                // 表单校验规则
                formRules: {},
                // 文案列表
                goodsShareCopyList: [],
                // 素材列表
                goodsMultiStyleList: [],
            },
            // 商品上架
            onSaleDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    orderDetailListJson: '',
                },
                // 表单校验规则
                formRules: {},
                // 商品详情列表
                orderDetailList: [],
                // 商品规格
                goodsSpecList: [],
            },
            formField: [
                { key: 'price', name: '价格', required: true },
                { key: 'stock', name: '库存', required: true },
                { key: 'id', name: 'id', hide: true },
            ],
            editTab: '1',
            goodsStyleTab: '',
            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    mobile: '',
                    distributorName: '',
                    distributorTel: '',
                    distributorLevelId: '',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                currentTableSelect: [],
            },
            goodsForbidSellDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    goodsId: '',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
            },
            //分销等级列表
            distributorLevelList: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Gd.Goods.data({
                ...queryData,
                isPrivateCustom: this.queryFormModel.isPrivateCustom,
            }).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        initSupplierList() {
            this.$api.Gd.Supplier.select({ goodsSendModeList: this.queryFormModel.goodsSendModeList }).then(json => {
                this.supplierList = json.data.data;
            });
        },
        offSaleGoods(row) {
            this.$confirm('是否下架该商品！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Gd.Goods.offSaleGoods({ goodsId: row.id }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onOrderNumInput(row) {
            this.$api.Gd.Goods.changeOrderNum({ id: row.id, orderNum: row.orderNum });
        },
        //扩展信息
        editGoodsExtendInfo(id) {
            this.editDialog.formModel.id = id;
            this.$api.Gd.Goods.getDetail({ id: this.editDialog.formModel.id }).then(json => {
                const res = json.data.data;
                this.editDialog.goodsShareCopyList = JSON.parse(res.goodsShareCopy || null) || [];
                this.initGoodsExtendInfoList();
                //初始化商品素材设置
                this.$api.Gd.GoodsMultiStyle.initGoodsMultiStyle({ goodsId: this.editDialog.formModel.id }).then(
                    json => {
                        const goodsMultiStyleList = json.data.data;
                        goodsMultiStyleList.forEach(item => {
                            item.goodsImage = item.goodsImage
                                ? item.goodsImage
                                      .split(',')
                                      .map(item => ({ name: item, address: res.basePathUrl + item }))
                                : [];
                            item.goodsDetailImage = item.goodsDetailImage
                                ? item.goodsDetailImage
                                      .split(',')
                                      .map(item => ({ name: item, address: res.basePathUrl + item }))
                                : [];
                        });
                        this.editDialog.goodsMultiStyleList = goodsMultiStyleList;
                        if (this.editDialog.goodsMultiStyleList.length > 0) {
                            this.goodsStyleTab = this.editDialog.goodsMultiStyleList[0].styleName;
                        }
                    },
                );
                this.editDialog.isVisible = true;
            });
        },
        initGoodsExtendInfoList() {
            let length = this.editDialog.goodsShareCopyList.length;
            for (let num = length; num < 3; num++) {
                this.editDialog.goodsShareCopyList.push({
                    copyName: '文案' + (num + 1),
                    copyContent: '',
                });
            }
        },
        onEditDialogConfirm() {
            const { editForm } = this.$refs;
            editForm.validate().then(() => {
                const goodsMultiStyleList = [];
                this.editDialog.goodsMultiStyleList.forEach(item => {
                    goodsMultiStyleList.push({
                        ...item,
                        goodsImage: item.goodsImage
                            .reduce((prev, curr) => {
                                prev.push(curr.name);
                                return prev;
                            }, [])
                            .join(','),
                        goodsDetailImage: item.goodsDetailImage
                            .reduce((prev, curr) => {
                                prev.push(curr.name);
                                return prev;
                            }, [])
                            .join(','),
                    });
                });
                this.$api.Gd.Goods.editGoods({
                    ...this.editDialog.formModel,
                    goodsShareCopy: JSON.stringify(this.editDialog.goodsShareCopyList),
                    goodsMultiStyleJson: JSON.stringify(goodsMultiStyleList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.editDialog.isVisible = false;
                });
            });
        },
        onEditDialogCancel() {
            const { editForm } = this.$refs;
            editForm.resetFields();
            const { formModel } = this.editDialog;
            formModel.id = '';
            this.editDialog.isVisible = false;
        },
        //商品上架
        onSaleGoods(row) {
            this.onSaleDialog.formModel.id = row.id;
            this.$api.Gd.Goods.getDetail({ id: this.onSaleDialog.formModel.id }).then(json => {
                const res = json.data.data;
                if (res.skuList.length !== 1) {
                    res.skuList.forEach(item => {
                        const specValueMap = {};
                        item.specDesc.split('_').forEach(specItem => {
                            specValueMap[specItem.split(':')[0]] = specItem.split(':')[1];
                        });
                        item.specValueMap = specValueMap;
                    });
                }
                this.onSaleDialog.orderDetailList = res.skuList;
                console.log(res.skuList);
                this.onSaleDialog.goodsSpecList = JSON.parse(res.specConfig || null) || {};
                this.onSaleDialog.isVisible = true;
            });
        },
        onSaleDialogConfirm() {
            const { onSaleForm } = this.$refs;
            onSaleForm.validate().then(() => {
                this.$api.Gd.Goods.onSaleGoods({
                    goodsId: this.onSaleDialog.formModel.id,
                    orderDetailListJson: JSON.stringify(this.onSaleDialog.orderDetailList),
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onSaleDialog.isVisible = false;
                    this.onQuery(this.pagination);
                });
            });
        },
        onSaleDialogCancel() {
            const { onSaleForm } = this.$refs;
            onSaleForm.resetFields();
            const { formModel } = this.onSaleDialog;
            formModel.id = '';
            this.onSaleDialog.isVisible = false;
        },
        spanMethod({ rowIndex, columnIndex }) {
            if (columnIndex < this.onSaleDialog.goodsSpecList.length - 1) {
                let colNum = 1;
                for (let j = columnIndex + 1; j < this.onSaleDialog.goodsSpecList.length; j++) {
                    colNum = colNum * this.onSaleDialog.goodsSpecList[j].v.length;
                }
                if (rowIndex % colNum === 0) {
                    return {
                        rowspan: colNum,
                        colspan: 1,
                    };
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0,
                    };
                }
            }
        },
        onBatch(field) {
            this.$prompt(`请输入${field.name}`, '温馨提示', {
                inputValidator(val) {
                    if (!val && field.required) {
                        return `${field.name}不能为空`;
                    } else if (/price|stock|costPrice/.test(field.key) && isNaN(val)) {
                        return `请填写金额`;
                    }
                    return true;
                },
            }).then(({ value }) => {
                if (/price|stock/.test(field.key)) {
                    value = +value;
                }
                this.onSaleDialog.orderDetailList.forEach(item => {
                    item[field.key] = value;
                });
                // const reg = new RegExp(`^${field.key}_.*`);
                // for (const key in this.formModel) {
                //     if (this.formModel.hasOwnProperty(key)) {
                //         if (reg.test(key)) {
                //             this.formModel[key] = value;
                //         }
                //     }
                // }
            });
        },
        //分销商禁售
        onGoodsForbidSell(row) {
            this.goodsForbidSellDialog.queryFormModel.goodsId = row.id;
            this.$nextTick(() => {
                this.onGoodsForbidSellDialogQuery();
            });
            this.goodsForbidSellDialog.isVisible = true;
        },
        onGoodsForbidSellDialogQuery(pagination) {
            return this.$api.Gd.GoodsForbidSell.data({
                ...this.goodsForbidSellDialog.queryFormModel,
                ...pagination,
            }).then(json => {
                const res = json.data;
                this.goodsForbidSellDialog.tableData = res.data;
                this.goodsForbidSellDialog.pagination = res.pagination;
            });
        },
        onGoodsForbidSellCancel() {
            this.goodsForbidSellDialog.isVisible = false;
        },
        onSelectDialogSelectionChange(val) {
            this.selectDialog.currentTableSelect = val;
        },
        onAddSelect() {
            this.selectDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDialogQuery();
            });
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Mb.Buyer.selectByPage({
                ...this.selectDialog.queryFormModel,
                isDistributor: 1,
                ...pagination,
                goodsForbidSellGoodsIdNE: this.goodsForbidSellDialog.queryFormModel.goodsId,
            }).then(json => {
                const res = json.data;
                this.selectDialog.tableData = res.data;
                this.selectDialog.pagination = res.pagination;
            });
        },
        onResetSelectDialog() {
            this.$refs.selectDialogQueryForm.resetFields();
        },
        onSelectConfirm() {
            const ids = this.selectDialog.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            if (ids.length === 0) {
                this.$message({
                    message: '请选择分销商',
                    type: 'error',
                });
                return;
            }
            this.$api.Gd.GoodsForbidSell.save({
                goodsIds: this.goodsForbidSellDialog.queryFormModel.goodsId,
                buyerIds: ids.join(','),
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.onGoodsForbidSellDialogQuery(this.goodsForbidSellDialog.pagination);
                this.selectDialog.isVisible = false;
            });
        },
        onSelectCancel() {
            this.selectDialog.isVisible = false;
        },
        onDeleteSelect(row) {
            this.$api.Gd.GoodsForbidSell.delete({ ids: row.id }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.onGoodsForbidSellDialogQuery(this.goodsForbidSellDialog.pagination);
            });
        },
        initDistributorLevelList() {
            this.$api.Mb.DistributorLevel.select().then(json => {
                this.distributorLevelList = json.data.data;
            });
        },
    },
    created() {
        console.log(1111);
        if (this.$route.name === 'cgd-goods-customGoods') {
            //定制商品
            this.queryFormModel.isPrivateCustom = 1;
            this.type = 2;
        } else {
            //普通商品
            this.queryFormModel.isPrivateCustom = 0;
            if (this.$route.name === 'gd-goods-goods') {
                this.type = 1;
                this.queryFormModel.goodsSendModeList = '1,2,3';
            } else {
                this.type = 3;
                this.queryFormModel.goodsSendModeList = '4';
            }
        }

        this.initSupplierList();
        this.initDistributorLevelList();
    },
};
</script>

<style lang="scss">
</style>
