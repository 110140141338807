<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <!--<template slot="secondHeader">
            <div class="second-header">second-header</div>
        </template>-->

        <!-- 内容 -->
        <el-form
            ref="form"
            slot="body"
            label-width="10em"
            size="small"
            :model="formModel"
            :rules="formRules"
        >
            <el-card
                size="small"
                shadow="never"
                header="基本信息"
            >
                <el-form-item
                    prop="goodsType"
                    label="商品类型"
                >
                    <RemoteSelect
                        remote="/sp/shop/getShopAvailableGoodsType"
                        placeholder="请输入"
                        v-model="formModel.goodsType"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        placeholder="请输入"
                        v-model="formModel.goodsName"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsShortName"
                    label="商品简称"
                >
                    <el-input
                        placeholder="请输入"
                        v-model="formModel.goodsShortName"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsSubtitle"
                    label="商品副标题"
                >
                    <el-input
                        placeholder="请输入"
                        v-model="formModel.goodsSubtitle"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsKeywords"
                    label="关键词"
                >
                    <el-input
                        type="textarea"
                        placeholder="请输入"
                        v-model="formModel.goodsKeywords"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsImage"
                    :label="type === 1? '商品列表图' : '商品主图'"
                >
                    <upload
                        draggable
                        action="/rs/attachment/uploadGoodsImage"
                        :limit="type === 1? 12 : 1"
                        :tips="type === 1? '建议尺寸：800*800像素,最多上传12张图片':'建议尺寸：800*800像素'"
                        v-model="formModel.goodsImage"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsDetailImage"
                    label="商品详情图"
                >
                    <upload
                        draggable
                        action="/rs/attachment/uploadGoodsImage"
                        :limit="12"
                        tips="建议尺寸：800*(不限)像素,最多上传12张图片"
                        v-model="formModel.goodsDetailImage"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsVideo"
                    label="商品视频"
                >
                    <upload
                        draggable
                        action="/rs/attachment/uploadGoodsImage"
                        :limit="1"
                        v-model="formModel.goodsVideo"
                        accept="video/*"
                        tips=""
                    />
                </el-form-item>
                <!--<el-form-item
                    v-if="type === 2"
                    prop="customFrontImage"
                    label="商品定制正面图"
                >
                    <upload
                        draggable
                        action="/rs/attachment/uploadGoodsImage"
                        :limit="1"
                        tips=""
                        v-model="formModel.customFrontImage"
                    />
                </el-form-item>
                <el-form-item
                    v-if="type === 2"
                    prop="customBackImage"
                    label="商品定制反面图"
                >
                    <upload
                        draggable
                        action="/rs/attachment/uploadGoodsImage"
                        :limit="1"
                        tips=""
                        v-model="formModel.customBackImage"
                    />
                </el-form-item>-->
                <el-form-item
                    v-if="type === 2"
                    prop="customDefaultImage"
                    label="商品定制模板图"
                >
                    <upload
                        draggable
                        action="/rs/attachment/uploadGoodsImage"
                        :limit="20"
                        tips=""
                        v-model="formModel.customDefaultImage"
                    />
                </el-form-item>
                <el-form-item
                    v-if="type === 2"
                    prop="customCombineConfig"
                    label="商品定制合成图配置"
                >
                    <el-input
                        type="textarea"
                        v-model="formModel.customCombineConfig"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="storeInitGoodsCatId"
                    label="门店初始商品类目"
                    v-if="$power(['goods:storeInitGoodsCat'])"
                >
                    <RemoteSelect
                        multiple
                        remote="/sp/storeInitGoodsCat/select"
                        placeholder="请输入"
                        :props="{id:'id',name:'goodsCatName'}"
                        v-model="formModel.storeInitGoodsCatId"
                    />
                </el-form-item>
                <el-form-item
                    prop="shopGoodsCatIds"
                    label="店铺类目"
                >
                    <TreePicker
                        remote="/sp/shopGoodsCat/selectAll"
                        v-model="formModel.shopGoodsCatIds"
                    />
                </el-form-item>
                <el-form-item
                    label="商品后台类目"
                >
                    <CascaderPicker
                        :api-class="GoodsCatApi"
                        v-model="formModel.goodsCatId"
                    />
                </el-form-item>
                <el-form-item
                    prop="supplierId"
                    label="供应商"
                >
                    <RemoteSelect
                        remote="/gd/supplier/select"
                        placeholder="请输入"
                        :props="{id:'id',name:'supplierName'}"
                        v-model="formModel.supplierId"
                        :remote-params="{goodsSendModeList:goodsSendModeList}"
                        @change="changeSupplier"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsSendMode"
                    label="商品发货方式"
                >
                    <dictionaries-picker
                        type="goodsSendMode"
                        v-model="formModel.goodsSendMode"
                        disabled
                    />
                </el-form-item>
                <el-form-item
                    key="freightTemplateId"
                    prop="freightTemplateId"
                    label="运费模板"
                    v-if="userData.shop.freightMode == 3"
                >
                    <RemoteSelect
                        remote="/sp/shopFreightTemplate/select"
                        placeholder="请输入"
                        :props="{id:'id',name:'templateName'}"
                        v-model="formModel.freightTemplateId"
                    />
                </el-form-item>
                <el-form-item
                    key="premiumTemplateId"
                    prop="premiumTemplateId"
                    label="保费模板"
                    v-if="(userData.shop.goodsConfig & 1) != 0"
                >
                    <RemoteSelect
                        clearable
                        remote="/sp/shopPremiumTemplate/select"
                        placeholder="请输入"
                        :props="{id:'id',name:'templateName'}"
                        v-model="formModel.premiumTemplateId"
                    />
                </el-form-item>
                <el-form-item
                    prop="aftersaleTemplateId"
                    label="售后模板"
                >
                    <RemoteSelect
                        clearable
                        remote="/sp/shopAftersaleTemplate/select"
                        placeholder="请输入"
                        :props="{id:'id',name:'templateName'}"
                        v-model="formModel.aftersaleTemplateId"
                    />
                </el-form-item>
                <el-form-item
                    prop="gstRate"
                    label="GST比例(%)"
                    v-if="userData.shop.goodsSendModes.split(',').includes('2')"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="formModel.gstRate"
                    />
                </el-form-item>
            </el-card>
            <el-card
                class="ma-t"
                size="small"
                shadow="never"
                header="价格库存"
            >
                <el-form-item
                    prop="goodsSn"
                    label="商品货号"
                >
                    <el-input
                        placeholder="请输入"
                        v-model="formModel.goodsSn"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsSpecList"
                    label="商品规格"
                >
                    <GoodsSpu
                        ref="GoodsSpu"
                        v-model="formModel.goodsSpecList"
                        :goods-image-base-url="formModel.goodsImageBaseUrl"
                    />
                </el-form-item>
                <el-form-item
                    v-if="hasGoodsSpecList"
                    prop="goodsSpecList"
                    label="规格明细"
                >
                    <GoodsSpec
                        ref="GoodsSpec"
                        :spu-data="formModel.goodsSpecList"
                        :goods-sn="formModel.goodsSn"
                        v-model="formModel.skuSpecList"
                    />
                </el-form-item>
                <template v-if="!hasGoodsSpecList">
                    <el-form-item
                        prop="stock"
                        label="库存"
                    >
                        <el-input
                            type="number"
                            placeholder="请输入"
                            v-model="formModel.stock"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="weight"
                        label="物流重量"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :precision="2"
                            v-model.number="formModel.weight"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="grossWeight"
                        label="毛重"
                    >
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :precision="2"
                            v-model.number="formModel.grossWeight"
                        />
                    </el-form-item>
                    <!--<el-form-item
                        prop="netWeight"
                        label="净重">
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :precision="2"
                            v-model.number="formModel.netWeight"/>
                    </el-form-item>-->
                    <el-form-item
                        prop="price"
                        label="价格"
                    >
                        <el-input
                            type="number"
                            placeholder="请输入"
                            v-model="formModel.price"
                        />
                    </el-form-item>
                </template>
                <el-form-item
                    prop="marketPrice"
                    label="划线价"
                >
                    <el-input
                        type="number"
                        placeholder="请输入"
                        v-model="formModel.marketPrice"
                    />
                </el-form-item>
                <el-form-item
                    prop="isShowMarketPrice"
                    label="是否显示划线价"
                >
                    <el-switch v-model="formModel.isShowMarketPrice" />
                </el-form-item>
            </el-card>
            <el-card
                class="ma-t"
                size="small"
                shadow="never"
                header="商品设置"
            >
                <el-form-item
                    label="计划上架时间"
                    label-width="9em"
                >
                    <el-date-picker
                        v-model="formModel.planOnSaleTime"
                        type="datetime"
                        value-format="timestamp"
                        placeholder="选择计划上架时间"
                    />
                </el-form-item>
                <el-form-item
                    label="计划下架时间"
                    label-width="9em"
                >
                    <el-date-picker
                        v-model="formModel.planOffSaleTime"
                        type="datetime"
                        value-format="timestamp"
                        placeholder="选择计划下架时间"
                    />
                </el-form-item>
                <el-form-item
                    prop="isPresellGoods"
                    label="是否预售商品"
                    label-width="9em"
                    v-if="(userData.shop.goodsConfig & 2) != 0"
                >
                    <el-switch
                        v-model="formModel.isPresellGoods"
                    />
                </el-form-item>
                <el-form-item
                    key="beginPresellTime"
                    prop="beginPresellTime"
                    label="预售开始时间"
                    label-width="9em"
                    v-if="formModel.isPresellGoods"
                >
                    <el-date-picker
                        v-model="formModel.beginPresellTime"
                        type="datetime"
                        value-format="timestamp"
                        placeholder="选择预售开始时间"
                    />
                </el-form-item>
                <el-form-item
                    key="presellEndPeriod"
                    prop="presellEndPeriod"
                    label="截单周期(天)"
                    label-width="9em"
                    v-if="formModel.isPresellGoods"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        v-model.number="formModel.presellEndPeriod"
                    />
                    (截单日期=预售开始时间+截单周期)
                </el-form-item>
                <el-form-item
                    key="presellShipPeriod"
                    prop="presellShipPeriod"
                    label="发货周期(天)"
                    label-width="9em"
                    v-if="formModel.isPresellGoods"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        v-model.number="formModel.presellShipPeriod"
                    />
                    (预计发货时间=预售开始时间+截单周期+发货周期)
                </el-form-item>
                <!--<el-form-item
                    prop="baseSales"
                    label="基础销量"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="0"
                        v-model.number="formModel.baseSales"
                    />
                </el-form-item>-->
            </el-card>
            <el-card
                class="ma-t"
                size="small"
                shadow="never"
                header="购买限制"
            >
                <el-form-item
                    label="订单最小购买数量"
                >
                    <el-input
                        type="number"
                        placeholder="请输入"
                        v-model="formModel.minPerOrderQuantity"
                    />
                </el-form-item>
                <el-form-item
                    label="订单最大购买数量"
                >
                    <el-input
                        type="number"
                        placeholder="请输入"
                        v-model="formModel.maxPerOrderQuantity"
                    />
                </el-form-item>
                <el-form-item
                    label="会员限购数量"
                >
                    <el-input
                        type="number"
                        placeholder="请输入"
                        v-model="formModel.maxMbOrderQuantity"
                    />
                </el-form-item>
                <el-form-item
                    label="会员等级限购"
                >
                    <el-checkbox-group v-model="buyerLevelLimit">
                        <el-checkbox
                            v-for="item in buyerLevelList"
                            :key="item.id"
                            :label="item.id"
                        >
                            {{ item.levelName }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item
                    label="分销等级限购"
                >
                    <el-checkbox-group v-model="distributorLevelLimit">
                        <el-checkbox
                            v-for="item in distributorLevelList"
                            :key="item.id"
                            :label="item.id"
                        >
                            {{ item.levelName }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-card>
            <el-card
                class="ma-t"
                size="small"
                shadow="never"
                header="分销设置"
                v-if="((1 & userData.shop.distributionIncomeMode) !== 0)
                    || ((2 & userData.shop.distributionIncomeMode) !== 0)
                    || ((4 & userData.shop.distributionIncomeMode) !== 0)
                    || ((16 & userData.shop.distributionIncomeMode) !== 0)
                    || ((32 & userData.shop.distributionIncomeMode) !== 0)"
            >
                <el-form-item
                    prop="shareIncomeRate"
                    label="分销分享赚比例(%)"
                    label-width="11em"
                    v-if="(1 & userData.shop.distributionIncomeMode) !== 0"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="formModel.shareIncomeRate"
                    />
                </el-form-item>
                <el-form-item
                    prop="topIncomeRate"
                    label="分销顶级提成比例(%)"
                    label-width="11em"
                    v-if="(2 & userData.shop.distributionIncomeMode) !== 0"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="formModel.topIncomeRate"
                    />
                </el-form-item>
                <el-form-item
                    prop="levelIncomeRate"
                    label="分销层级提成比例"
                    label-width="11em"
                    v-if="(4 & userData.shop.distributionIncomeMode) !== 0 || (8 & userData.shop.distributionIncomeMode) !== 0"
                >
                    <el-table
                        stripe
                        border
                        size="small"
                        :data="levelIncomeRateList"
                        style="width: 100%"
                    >
                        <el-table-column
                            prop="name"
                            label="分销层级"
                            width="120"
                        />
                        <el-table-column
                            prop="levelIncomeRate"
                            label="提成比例(%)"
                            min-width="220"
                        >
                            <template slot-scope="scope">
                                <el-input-number
                                    controls-position="right"
                                    :min="0"
                                    :precision="2"
                                    v-model.number="scope.row.levelIncomeRate"
                                />
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
                <el-form-item
                    prop="liveShareIncomeRate"
                    label="直播分享赚比例(%)"
                    label-width="11em"
                    v-if="(16 & userData.shop.distributionIncomeMode) !== 0"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="formModel.liveShareIncomeRate"
                    />
                </el-form-item>
                <el-form-item
                    prop="anchorIncomeRate"
                    label="主播提成比例(%)"
                    label-width="11em"
                    v-if="(32 & userData.shop.distributionIncomeMode) !== 0"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="formModel.anchorIncomeRate"
                    />
                </el-form-item>
            </el-card>
        </el-form>

        <div class="ta-c pa-a">
            <el-button
                type="primary"
                @click="onSave"
            >
                保存
            </el-button>
            <el-button
                type="success"
                @click="onSave(true)"
            >
                保存并上架
            </el-button>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import GoodsSpu from './_goodsSpu';
import GoodsSpec from './_goodsSpec';

export default {
    name: 'AddGoods',
    mixins: [pagesMixin],
    components: { GoodsSpu, GoodsSpec },
    data() {
        return {
            type: '',
            GoodsCatApi: this.$api.Gd.GoodsCat,
            formModel: {
                id: '',
                goodsType: '',
                goodsCatName: '',
                goodsName: '',
                goodsShortName: '',
                goodsSubtitle: '',
                goodsKeywords: '',
                goodsImage: [],
                goodsDetailImage: [],
                goodsVideo: [],
                shopGoodsCatIds: [], // 店铺类目
                goodsCatId: '', // 商品后台类目
                supplierId: '',
                supplierName: '',
                freightTemplateId: '',
                premiumTemplateId: '',
                aftersaleTemplateId: '',
                gstRate: undefined,

                /*价格库存*/
                goodsSn: '',
                goodsSpecList: [], // 商品规格
                skuSpecList: [],
                stock: '',
                costPrice: '',
                price: '',
                marketPrice: '',
                isShowMarketPrice: true,

                weight: '',
                netWeight: '',
                grossWeight: '',

                /*购买限制*/
                minPerOrderQuantity: '',
                maxPerOrderQuantity: '',
                maxMbOrderQuantity: '',
                goodsPermission: '',

                planOnSaleTime: '',
                planOffSaleTime: '',

                isPresellGoods: false,
                beginPresellTime: '',
                presellEndPeriod: '',
                presellShipPeriod: '',

                goodsSendMode: '',

                goodsImageBaseUrl: 'http://imgp.zteup.com/upload/sys/goodsimage',

                isPrivateCustom: '',
                customFrontImage: [],
                customBackImage: [],
                customDefaultImage: [],
                customCombineConfig: '',

                baseSales: 0,

                shareIncomeRate: undefined,
                topIncomeRate: undefined,
                liveShareIncomeRate: undefined,
                anchorIncomeRate: undefined,

                storeInitGoodsCatId: [],
            },
            formRules: {
                goodsType: {
                    required: true,
                    message: '不能为空',
                },
                goodsSendMode: {
                    required: true,
                    message: '不能为空',
                },
                goodsCatName: {
                    required: true,
                    message: '不能为空',
                },
                goodsName: {
                    required: true,
                    message: '不能为空',
                },
                goodsShortName: {
                    required: true,
                    message: '不能为空',
                },
                goodsImage: {
                    type: 'array',
                    required: true,
                    message: '不能为空',
                },
                goodsDetailImage: {
                    type: 'array',
                    required: true,
                    message: '不能为空',
                },
                // 店铺类目
                goodsCatId: '', // 商品后台类目
                supplierId: {
                    required: true,
                    message: '不能为空',
                },
                freightTemplateId: {
                    required: true,
                    message: '不能为空',
                },
                /*价格库存*/
                goodsSn: {
                    required: true,
                    message: '不能为空',
                },
                stock: {
                    required: true,
                    message: '不能为空',
                },
                costPrice: {
                    required: true,
                    message: '不能为空',
                },
                price: {
                    required: true,
                    message: '不能为空',
                },
                marketPrice: {
                    required: true,
                    message: '不能为空',
                },
                beginPresellTime: {
                    required: true,
                    message: '不能为空',
                },
                presellEndPeriod: {
                    required: true,
                    message: '不能为空',
                },
                presellShipPeriod: {
                    required: true,
                    message: '不能为空',
                },
                customFrontImage: {
                    type: 'array',
                    required: true,
                    message: '不能为空',
                },
                customBackImage: {
                    type: 'array',
                    required: true,
                    message: '不能为空',
                },
                customDefaultImage: {
                    type: 'array',
                    required: true,
                    message: '不能为空',
                },
            },
            //分销层级设置
            levelIncomeRateList: [],
            buyerLevelList: [],
            distributorLevelList: [],
            buyerLevelLimit: [],
            distributorLevelLimit: [],
            goodsSendModeList: '',
        };
    },
    computed: {
        goodsId() {
            return this.$route.params.goodsId;
        },
        hasGoodsSpecList() {
            return this.formModel.goodsSpecList && this.formModel.goodsSpecList.length;
        },
    },
    methods: {
        recoverFormModel(data) {
            Object.keys(this.formModel).forEach(key => {
                // if (key === 'goodsSpecList') return;
                // if (key === 'goodsImage') return;
                // if (key === 'goodsDetailImage') return;
                // if (key === 'skuSpecList') return;
                const val = data[key];
                this.formModel[key] = val === undefined || val === null ? this.formModel[key] : val;
            });
        },
        getSaveData() {
            const data = {
                ...this.formModel,
            };

            // 店铺类目
            data.shopGoodsCatIds = data.shopGoodsCatIds
                .reduce((prev, curr) => {
                    prev.push(curr);
                    return prev;
                }, [])
                .join(',');
            data.goodsImage = data.goodsImage
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
            data.goodsDetailImage = data.goodsDetailImage
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
            data.goodsVideo = data.goodsVideo
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');

            data.goodsSpecList = data.goodsSpecList.map(spec => {
                const _spec = {
                    pid: spec.pid,
                    pn: spec.pn,
                    v: spec.v || [],
                };
                _spec.v = _spec.v.map(sku => {
                    const vimgArr = sku.vimgModel || [];
                    const vimgModel = vimgArr[0] || {};
                    return {
                        vid: sku.vid,
                        vn: sku.vn,
                        vimg: vimgModel.name || '',
                    };
                });
                return _spec;
            });

            data.levelIncomeRate = JSON.stringify(this.levelIncomeRateList);
            // data.skuSpecList;
            const goodsPermissionList = [];
            this.buyerLevelLimit.forEach(item => {
                goodsPermissionList.push('buyerLevel-' + item);
            });
            this.distributorLevelLimit.forEach(item => {
                goodsPermissionList.push('distributorLevel-' + item);
            });
            if (goodsPermissionList.length === 0) {
                data.goodsPermission = '';
            } else {
                data.goodsPermission = goodsPermissionList.join(',');
            }

            data.customFrontImage = (data.customFrontImage || [])
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
            data.customBackImage = (data.customBackImage || [])
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
            data.customDefaultImage = (data.customDefaultImage || [])
                .reduce((prev, curr) => {
                    prev.push(curr.name);
                    return prev;
                }, [])
                .join(',');
            if (data.storeInitGoodsCatId) {
                data.storeInitGoodsCatIds = data.storeInitGoodsCatId.join(',');
            }

            return data;
        },
        onSave(publish) {
            const { form, GoodsSpu, GoodsSpec } = this.$refs;
            const validateList = [form];
            GoodsSpu && validateList.push(GoodsSpu);
            GoodsSpec && GoodsSpec.form && validateList.push(GoodsSpec.form);
            this.$utils.Common.validateForm(validateList, true)
                .then((/*valid*/) => {
                    const data = this.getSaveData();
                    let fn = 'save';
                    if (publish === true) {
                        fn = 'saveAndPublish';
                    }
                    this.$api.Gd.Goods[fn](data).then(json => {
                        const res = json.data;
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.$router.back();
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        },
        initLevelIncomeRateList() {
            const newlevelIncomeRateList = [];
            for (let num = 1; num <= this.userData.shop.distributionLevel; num++) {
                let isExit = false;
                this.levelIncomeRateList.forEach(item => {
                    if (item.id === num) {
                        isExit = true;
                        newlevelIncomeRateList.push(item);
                    }
                });
                if (!isExit) {
                    const levelIncomeRate = {};
                    levelIncomeRate.id = num;
                    levelIncomeRate.name = num + '级分销商';
                    newlevelIncomeRateList.push(levelIncomeRate);
                }
            }
            newlevelIncomeRateList.sort(function(a, b) {
                return a.id - b.id;
            });
            this.levelIncomeRateList = [...newlevelIncomeRateList];
        },
        initBuyerLevelList() {
            this.$api.Mb.BuyerLevel.select().then(json => {
                const res = json.data.data;
                this.buyerLevelList = res;
            });
        },
        initDistributorLevelList() {
            this.$api.Mb.DistributorLevel.select().then(json => {
                const res = json.data.data;
                this.distributorLevelList = res;
            });
        },
        changeSupplier() {
            this.$api.Gd.Supplier.getDetail({ id: this.formModel.supplierId }).then(json => {
                if (json.data.data.goodsSendMode) {
                    this.formModel.goodsSendMode = json.data.data.goodsSendMode;
                }
            });
        },
    },
    created() {
        if (this.$route.name === 'cgd-goods-addCustomGoods') {
            //定制商品
            this.formModel.isPrivateCustom = 1;
            this.type = 2;
        } else {
            this.formModel.isPrivateCustom = 0;
            this.type = 1;
            if (this.$route.name === 'gd-goods-addGoods') {
                this.goodsSendModeList = '1,2,3';
            } else {
                this.goodsSendModeList = '4';
            }
        }
        if (this.goodsId) {
            this.$api.Gd.Goods.getGoodsDetailVo({ id: this.goodsId }).then(json => {
                const res = json.data;
                const data = res.data;
                data.goodsImageBaseUrl = data.goodsImageBaseUrl.replace(/\/$/, '');
                data.goodsImage = data.goodsImage
                    .split(',')
                    .map(item => ({ name: item, address: `${data.goodsImageBaseUrl}/${item}` }));
                data.goodsDetailImage = data.goodsDetailImage
                    .split(',')
                    .map(item => ({ name: item, address: `${data.goodsImageBaseUrl}/${item}` }));
                if (!data.goodsVideo) {
                    data.goodsVideo = [];
                } else {
                    data.goodsVideo = data.goodsVideo
                        .split(',')
                        .map(item => ({ name: item, address: `${data.goodsImageBaseUrl}/${item}` }));
                }
                if (data.customFrontImage) {
                    data.customFrontImage = data.customFrontImage
                        .split(',')
                        .map(item => ({ name: item, address: `${data.goodsImageBaseUrl}/${item}` }));
                }
                if (data.customBackImage) {
                    data.customBackImage = data.customBackImage
                        .split(',')
                        .map(item => ({ name: item, address: `${data.goodsImageBaseUrl}/${item}` }));
                }
                if (data.customDefaultImage) {
                    data.customDefaultImage = data.customDefaultImage
                        .split(',')
                        .map(item => ({ name: item, address: `${data.goodsImageBaseUrl}/${item}` }));
                }
                // 店铺类目
                data.shopGoodsCatIds = (data.shopGoodsCatIds || '').split(',').map(item => +item);
                data.storeInitGoodsCatId = (data.storeInitGoodsCatIds || '').split(',').map(item => +item);
                // 商品规格
                const arr = [];
                for (let i = 0, item; (item = data.goodsSpecList[i++]); ) {
                    for (let n = 0, item2; (item2 = item.v[n++]); ) {
                        item2.spec = item.pid + ':' + item2.vid;
                        item2.desc = item.pn + ':' + item2.vn;
                    }
                    arr.push(item);
                }
                data.goodsSpecList = arr;
                this.recoverFormModel(data);

                this.levelIncomeRateList = JSON.parse(data.levelIncomeRate) || [];
                this.initLevelIncomeRateList();

                if (data.goodsPermission) {
                    data.goodsPermission.split(',').forEach(item => {
                        const limitItem = item.split('-');
                        if (limitItem[0] === 'buyerLevel') {
                            this.buyerLevelLimit.push(+limitItem[1]);
                        } else if (limitItem[0] === 'distributorLevel') {
                            this.distributorLevelLimit.push(+limitItem[1]);
                        }
                    });
                }
            });
        } else {
            this.initLevelIncomeRateList();
        }
        this.initBuyerLevelList();
        this.initDistributorLevelList();
    },
};
</script>

<style lang="scss">
</style>
