<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.goodsStatusType"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="出售中"
                    name="1"
                />
                <el-tab-pane
                    label="已售罄"
                    name="2"
                />
                <el-tab-pane
                    label="仓库中"
                    name="3"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <!--<el-form-item
                    prop="goodsSn"
                    label="商品货号"
                >
                    <el-input
                        v-model="queryFormModel.goodsSn"
                        placeholder="请输入商品货号"
                    />
                </el-form-item>-->
                <el-form-item
                    prop="supplierName"
                    label="供应商"
                >
                    <el-input
                        v-model="queryFormModel.supplierName"
                        placeholder="请输入供应商"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="skuSn"
                    label="sku货号"
                >
                    <el-input
                        v-model="queryFormModel.skuSn"
                        placeholder="请输入sku货号"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <div
                class="ma-b"
            >
                <el-form
                    class="second-header npa-b"
                    size="small"
                    :inline="true"
                >
                    <div>
                        <el-form-item
                            label-width="9em"
                            label="易鲜：美元汇率"
                        >
                            <el-input-number
                                @change="saveSkuPriceConfig"
                                controls-position="right"
                                :min="0"
                                :precision="4"
                                v-model.number="exchangeRate"
                            />
                        </el-form-item>
                        <el-form-item
                            label="代理价比例"
                        >
                            <el-input-number
                                @change="saveSkuPriceConfig"
                                controls-position="right"
                                :min="0"
                                :precision="4"
                                v-model.number="proxyPriceRatio"
                            />
                        </el-form-item>
                        <el-form-item
                            label="零售价比例"
                        >
                            <el-input-number
                                @change="saveSkuPriceConfig"
                                controls-position="right"
                                :min="0"
                                :precision="4"
                                v-model.number="retailPriceRatio"
                            />
                        </el-form-item>
                    </div>
                    <div>
                        <el-form-item
                            label-width="9em"
                            label="花果山：加币汇率"
                        >
                            <el-input-number
                                @change="saveSkuPriceConfig"
                                controls-position="right"
                                :min="0"
                                :precision="4"
                                v-model.number="exchangeRate1"
                            />
                        </el-form-item>
                        <el-form-item
                            label="零售价比例"
                        >
                            <el-input-number
                                @change="saveSkuPriceConfig"
                                controls-position="right"
                                :min="0"
                                :precision="4"
                                v-model.number="retailPriceRatio1"
                            />
                        </el-form-item>
                    </div>
                </el-form>
            </div>
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <!--<el-table-column
                    prop="goodsSn"
                    label="商品货号"
                    width="150"
                />-->
                <el-table-column
                    prop="supplierName"
                    label="供应商"
                    width="75"
                />
                <el-table-column
                    prop="goodsName"
                    label="商品名称"
                    min-width="50"
                />
                <el-table-column
                    prop="skuSn"
                    label="sku货号"
                    width="150"
                />
                <el-table-column
                    prop="specDesc"
                    label="商品规格"
                    min-width="50"
                />
                <el-table-column
                    prop="showCostPrice"
                    label="成本价"
                    width="100"
                />
                <el-table-column
                    label="易鲜"
                    align="center"
                >
                    <el-table-column
                        prop="exchangeRatePrice"
                        label="汇率价"
                        width="60"
                    >
                        <template slot-scope="scope">
                            <!--成本价/汇率-->
                            {{ scope.row.maxCostPrice / exchangeRate | number }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="recommendedProxyPrice"
                        label="建议代理价"
                        width="85"
                    >
                        <template slot-scope="scope">
                            <!--成本价/汇率*代理价比例-->
                            {{ scope.row.maxCostPrice / exchangeRate * proxyPriceRatio | number }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="proxyPrice"
                        label="实际代理价"
                        width="150"
                    >
                        <template slot-scope="scope">
                            <el-input-number
                                size="small"
                                @change="saveProxyPrice(scope.row)"
                                controls-position="right"
                                :min="0"
                                :precision="2"
                                v-model.number="scope.row.proxyPrice"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="proxyPriceGrossProfitMargin"
                        label="代理价毛利率"
                        width="95"
                    >
                        <template slot-scope="scope">
                            <!--（实际代理价-汇率价）/实际代理价-->
                            {{ ((scope.row.proxyPrice - (scope.row.maxCostPrice / exchangeRate).toFixed(2)) / scope.row.proxyPrice * 100) || 0 | number }}%
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="recommendedRetailPrice"
                        label="建议零售价"
                        width="85"
                    >
                        <template slot-scope="scope">
                            <!--建议代理价*零售价比例-->
                            {{ (scope.row.maxCostPrice / exchangeRate * proxyPriceRatio).toFixed(2) * retailPriceRatio | number }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="retailPrice"
                        label="实际零售价"
                        width="150"
                    >
                        <template slot-scope="scope">
                            <el-input-number
                                size="small"
                                @change="saveRetailPrice(scope.row)"
                                controls-position="right"
                                :min="0"
                                :precision="2"
                                v-model.number="scope.row.retailPrice"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="retailPriceGrossProfitMargin"
                        label="零售价毛利率"
                        width="95"
                    >
                        <template slot-scope="scope">
                            <!--（实际零售价-汇率价）/实际零售价-->
                            {{ ((scope.row.retailPrice - (scope.row.maxCostPrice / exchangeRate).toFixed(2)) / scope.row.retailPrice * 100) || 0 | number }}%
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column
                    label="花果山"
                    align="center"
                >
                    <el-table-column
                        label="汇率价"
                        width="60"
                    >
                        <template slot-scope="scope">
                            <!--成本价/汇率-->
                            {{ scope.row.maxCostPrice / exchangeRate1 | number }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="建议零售价"
                        width="100"
                    >
                        <template slot-scope="scope">
                            <!--汇率价*零售价比例-->
                            {{ scope.row.maxCostPrice / exchangeRate1 * retailPriceRatio1 | number }}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="实际零售价"
                        width="150"
                    >
                        <template slot-scope="scope">
                            <el-input-number
                                size="small"
                                @change="saveRetailPriceRedis(scope.row)"
                                controls-position="right"
                                :min="0"
                                :precision="2"
                                v-model.number="scope.row.retailPrice1"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="零售价毛利率"
                        width="100"
                    >
                        <template slot-scope="scope">
                            <!--（实际零售价-汇率价）/实际零售价-->
                            {{ ((scope.row.retailPrice1 - (scope.row.maxCostPrice / exchangeRate1).toFixed(2)) / scope.row.retailPrice1 * 100) || 0 | number }}%
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'SkuCost',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                supplierName: '', // 供应商
                goodsSn: '', // 商品货号
                goodsName: '', // 商品名称
                skuSn: '', // 商品名称
                goodsStatusType: '1', // 商品状态类型
            },
            // 表格数据
            tableData: [],
            exchangeRate: '1',
            proxyPriceRatio: '1',
            retailPriceRatio: '1',
            exchangeRate1: '1',
            retailPriceRatio1: '1',
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Gd.SkuFreightCost.skuCostList(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        init() {
            this.$api.Gd.SkuFreightCost.getSkuPriceConfig().then(json => {
                const res = json.data.data;
                if (res) {
                    this.exchangeRate = res.exchangeRate || 1;
                    this.proxyPriceRatio = res.proxyPriceRatio || 1;
                    this.retailPriceRatio = res.retailPriceRatio || 1;
                    this.exchangeRate1 = res.exchangeRate1 || 1;
                    this.retailPriceRatio1 = res.retailPriceRatio1 || 1;
                }
            });
        },
        saveSkuPriceConfig() {
            this.$api.Gd.SkuFreightCost.saveSkuPriceConfig({
                exchangeRate: this.exchangeRate,
                proxyPriceRatio: this.proxyPriceRatio,
                retailPriceRatio: this.retailPriceRatio,
                exchangeRate1: this.exchangeRate1,
                retailPriceRatio1: this.retailPriceRatio1,
            });
        },
        saveProxyPrice(row) {
            this.$api.Gd.SkuFreightCost.saveProxyPrice({ id: row.id, proxyPrice: row.proxyPrice }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
            });
        },
        saveRetailPrice(row) {
            this.$api.Gd.SkuFreightCost.saveRetailPrice({ id: row.id, retailPrice: row.retailPrice }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
            });
        },
        saveRetailPriceRedis(row) {
            this.$api.Gd.SkuFreightCost.saveRetailPriceRedis({ id: row.id, retailPrice1: row.retailPrice1 }).then(
                json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                },
            );
        },
    },
    created() {
        this.init();
    },
};
</script>

<style lang="scss">
</style>
