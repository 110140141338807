<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.goodsStatusType"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="出售中"
                    name="1"
                />
                <el-tab-pane
                    label="已售罄"
                    name="2"
                />
                <el-tab-pane
                    label="仓库中"
                    name="3"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="goodsSn"
                    label="商品货号"
                >
                    <el-input
                        v-model="queryFormModel.goodsSn"
                        placeholder="请输入商品货号"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="skuSn"
                    label="sku货号"
                >
                    <el-input
                        v-model="queryFormModel.skuSn"
                        placeholder="请输入sku货号"
                    />
                </el-form-item>
                <el-form-item
                    prop="thirdPlatformSkuSnNull"
                    label="是否已设置"
                >
                    <el-select
                        v-model="queryFormModel.thirdPlatformSkuSnNull"
                        placeholder="请选择"
                    >
                        <el-option
                            label="全部"
                            value=""
                        />
                        <el-option
                            label="是"
                            value="0"
                        />
                        <el-option
                            label="否"
                            value="1"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="goodsSn"
                    label="商品货号"
                    width="150"
                />
                <el-table-column
                    prop="goodsName"
                    label="商品名称"
                    min-width="50"
                />
                <el-table-column
                    prop="skuSn"
                    label="sku货号"
                    width="150"
                />
                <el-table-column
                    prop="specDesc"
                    label="商品规格"
                    min-width="100"
                />
                <el-table-column
                    prop="thirdPlatformSkuSn"
                    label="第三方平台SKU编码"
                    width="200"
                >
                    <template slot-scope="scope">
                        <el-input
                            @change="saveThirdPlatformSkuSn(scope.row)"
                            v-model="scope.row.thirdPlatformSkuSn"
                            auto-complete="off"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="thirdPlatformSkuQuantity"
                    label="第三方平台SKU数量"
                    width="200"
                >
                    <template slot-scope="scope">
                        <el-input
                            @change="saveThirdPlatformSkuQuantity(scope.row)"
                            v-model="scope.row.thirdPlatformSkuQuantity"
                            auto-complete="off"
                        />
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'ThirdPlatformSku',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                goodsSn: '', // 商品货号
                goodsName: '', // 商品名称
                skuSn: '', // 商品名称
                goodsStatusType: '1', // 商品状态类型
                thirdPlatformSkuSnNull: '',
            },
            // 表格数据
            tableData: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Gd.SkuMemberPrice.skuMemberPriceList(queryData).then(json => {
                const res = json.data.data.result;

                this.tableData = res.data;
                this.pagination = res.pagination;

                return Promise.resolve({ data: res });
            });
        },
        saveThirdPlatformSkuSn(row) {
            this.$api.Gd.Goods.saveThirdPlatformSkuSn({ id: row.id, thirdPlatformSkuSn: row.thirdPlatformSkuSn }).then(
                json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                },
            );
        },
        saveThirdPlatformSkuQuantity(row) {
            this.$api.Gd.Goods.saveThirdPlatformSkuQuantity({
                id: row.id,
                thirdPlatformSkuQuantity: row.thirdPlatformSkuQuantity,
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
            });
        },
    },
};
</script>

<style lang="scss">
</style>
