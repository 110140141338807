<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.goodsStatusType"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="出售中"
                    name="1"
                />
                <el-tab-pane
                    label="已售罄"
                    name="2"
                />
                <el-tab-pane
                    label="仓库中"
                    name="3"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="goodsSn"
                    label="商品货号"
                >
                    <el-input
                        v-model="queryFormModel.goodsSn"
                        placeholder="请输入商品货号"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsCatId"
                    label="商品类目"
                >
                    <CascaderPicker
                        :api-class="selectApi"
                        v-model="queryFormModel.goodsCatId"
                    />
                </el-form-item>
                <el-form-item
                    prop="supplierId"
                    label="供应商"
                >
                    <el-select
                        v-model="queryFormModel.supplierId"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in supplierList"
                            :key="item.id"
                            :label="item.supplierName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        size="small"
                        @click="batchSetBaseSales"
                    >
                        批量设置
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    prop="id"
                    label="ID"
                    width="50"
                />
                <el-table-column
                    label="商品图片"
                    width="120"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.mainImageUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="goodsName"
                    label="商品名称"
                    min-width="100"
                />
                <el-table-column
                    prop="goodsSn"
                    label="商品货号"
                    min-width="50"
                />
                <el-table-column
                    prop="goodsCatName"
                    label="商品类目"
                    min-width="50"
                />
                <el-table-column
                    prop="supplierName"
                    label="供应商名称"
                    min-width="50"
                />
                <el-table-column
                    prop="price"
                    label="销售价"
                    min-width="50"
                />
                <el-table-column
                    prop="marketPrice"
                    label="市场价"
                    min-width="50"
                />
                <el-table-column
                    prop="stock"
                    label="库存"
                    min-width="50"
                />
                <el-table-column
                    prop="goodsStatusName"
                    label="商品状态"
                    min-width="50"
                />
                <el-table-column
                    prop="baseSales"
                    label="基础销量"
                    width="200"
                >
                    <template slot-scope="scope">
                        <el-input-number
                            controls-position="right"
                            :min="0"
                            :max="999999"
                            v-model.number="scope.row.baseSales"
                            @change="onBaseSalesInput(scope.row)"
                        />
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 批量设置基础销量 -->
        <el-dialog
            title="批量设置基础销量"
            center
            width="600px"
            :visible.sync="baseSalesDialog.isVisible"
            @closed="onBaseSalesDialogCancel"
        >
            <el-form
                ref="baseSalesForm"
                size="small"
                :model="baseSalesDialog.formModel"
                :rules="baseSalesDialog.formRules"
            >
                <el-form-item
                    label-width="6em"
                >
                    <span class="tc-red">批量设置查询出来的商品列表</span>
                </el-form-item>
                <el-form-item
                    key="baseSalesType"
                    prop="baseSalesType"
                    label="设置方式"
                    label-width="6em"
                >
                    <el-select v-model="baseSalesDialog.formModel.baseSalesType">
                        <el-option
                            key="1"
                            label="固定数量"
                            value="1"
                        />
                        <el-option
                            key="2"
                            label="按比例"
                            value="2"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    key="baseSales"
                    prop="baseSales"
                    label="基础销量"
                    label-width="6em"
                    v-if="baseSalesDialog.formModel.baseSalesType == 1"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="0"
                        v-model.number="baseSalesDialog.formModel.baseSales"
                    />
                </el-form-item>
                <el-form-item
                    key="baseSalesPer"
                    prop="baseSalesPer"
                    label="增加比例"
                    label-width="6em"
                    v-if="baseSalesDialog.formModel.baseSalesType == 2"
                >
                    <el-input-number
                        controls-position="right"
                        :min="0"
                        :precision="2"
                        v-model.number="baseSalesDialog.formModel.baseSalesPer"
                    />
                    %
                    <div>
                        以原基础销量，按比例增加
                    </div>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onBaseSalesDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onBaseSalesDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'GoodsBaseSales',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                goodsName: '', // 商品名称
                goodsSn: '', // 商品货号
                goodsCatId: '', // 商品类目
                supplierId: '', // 供应商
                goodsStatusType: '1', // 商品状态类型
                isPrivateCustom: '',
            },
            // 表格数据
            tableData: [],
            //批量设置基础销量
            baseSalesDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    baseSalesType: '1',
                    baseSales: '',
                    baseSalesPer: '',
                },
                // 表单校验规则
                formRules: {
                    baseSalesType: {
                        required: true,
                        message: '请选择设置方式',
                    },
                    baseSales: {
                        required: true,
                        message: '请输入基础销量',
                        trigger: 'blur',
                    },
                    baseSalesPer: {
                        required: true,
                        message: '请输入增加比例',
                        trigger: 'blur',
                    },
                },
                // 订单数据
                orderData: {},
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            if (this.$route.name === 'cgd-goods-customGoods') {
                //定制商品
                this.queryFormModel.isPrivateCustom = 1;
                this.type = 2;
            } else {
                this.queryFormModel.isPrivateCustom = 0;
                this.type = 1;
            }
            return this.$api.Gd.Goods.data({
                ...queryData,
                isPrivateCustom: this.queryFormModel.isPrivateCustom,
            }).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        initSupplierList() {
            this.$api.Gd.Supplier.select().then(json => {
                this.supplierList = json.data.data;
            });
        },
        onBaseSalesInput(row) {
            this.$api.Gd.Goods.changeBaseSales({ id: row.id, baseSales: row.baseSales });
        },
        //批量设置基础销量
        batchSetBaseSales() {
            this.baseSalesDialog.isVisible = true;
        },
        onBaseSalesDialogConfirm() {
            const { baseSalesForm } = this.$refs;
            baseSalesForm.validate().then(() => {
                this.$api.Gd.Goods.batchSetBaseSales({
                    ...this.baseSalesDialog.formModel,
                    ...this.queryFormModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.baseSalesDialog.isVisible = false;
                });
            });
        },
        onBaseSalesDialogCancel() {
            const { baseSalesForm } = this.$refs;
            baseSalesForm.resetFields();
            this.baseSalesDialog.isVisible = false;
        },
    },
    created() {
        this.queryFormModel.isPrivateCustom = 0;

        this.initSupplierList();
    },
};
</script>

<style lang="scss">
</style>
