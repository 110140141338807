<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.goodsStatusType"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="出售中"
                    name="1"
                />
                <el-tab-pane
                    label="已售罄"
                    name="2"
                />
                <el-tab-pane
                    label="仓库中"
                    name="3"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="goodsSn"
                    label="商品货号"
                >
                    <el-input
                        v-model="queryFormModel.goodsSn"
                        placeholder="请输入商品货号"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="skuSn"
                    label="sku货号"
                >
                    <el-input
                        v-model="queryFormModel.skuSn"
                        placeholder="请输入sku货号"
                    />
                </el-form-item>
                <el-form-item
                    prop="supplierId"
                    label="供应商"
                >
                    <el-select
                        v-model="queryFormModel.supplierId"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in supplierList"
                            :key="item.id"
                            :label="item.supplierName"
                            :value="item.id + ''"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!--<div
                class="ma-b"
            >
                <span
                    class="m-t">
                    批量设置:
                    <span v-show="!isSetBatchPrice">
                        <el-button
                            v-for="(item, index) in levelList"
                            :key="index"
                            type="text"
                            size="small"
                            @click="onBatchSetPrice(item)"
                        >{{ item.levelName }}
                        </el-button>
                    </span>
                    <span v-show="isSetBatchPrice">
                        <el-input-number
                            size="small"
                            controls-position="right"
                            :min="0"
                            :precision="2"
                            v-model.number="batchPrice"/>
                        <el-button
                            type="text"
                            size="small"
                            @click="onConfirmBatchSetPrice()"
                        >保存
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onCancelBatchSetPrice()"
                        >取消
                        </el-button>
                    </span>
                </span>
            </div>-->
            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column
                    label="商品图片"
                    width="70"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.specImageUrl"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="goodsSn"
                    label="商品货号"
                    min-width="100"
                />
                <el-table-column
                    prop="goodsName"
                    label="商品名称"
                    min-width="150"
                />
                <el-table-column
                    prop="skuSn"
                    label="sku货号"
                    min-width="100"
                />
                <el-table-column
                    prop="specDesc"
                    label="商品规格"
                    min-width="100"
                />
                <el-table-column
                    prop="supplierName"
                    label="供应商名称"
                    min-width="80"
                />
                <el-table-column
                    prop="costPrice"
                    label="商品成本"
                    width="150"
                >
                    <template slot-scope="scope">
                        <el-input-number
                            size="small"
                            @change="saveCostPrice(scope.row)"
                            controls-position="right"
                            :min="0"
                            :precision="2"
                            v-model.number="scope.row.costPrice"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    v-for="(item,index) in levelList"
                    :key="index"
                    :label="item.levelName + '加价'"
                    width="150"
                >
                    <template slot-scope="scope">
                        <el-input-number
                            @change="saveBuyerPrice(scope.row, item.id, scope.row.buyerLevelPriceList[index].price)"
                            size="small"
                            controls-position="right"
                            :precision="2"
                            v-model.number="scope.row.buyerLevelPriceList[index].price"
                        />
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'BuyerPrice',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                goodsSn: '', // 商品货号
                goodsName: '', // 商品名称
                skuSn: '', // 商品名称
                goodsStatusType: '1', // 商品状态类型
                supplierId: '',
                goodsSendModeList: '',
            },
            // 表格数据
            tableData: [],
            levelList: [],
            isSetBatchPrice: false,
            batchPrice: '',
            setLevelId: '',
            supplierList: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Gd.SkuMemberPrice.skuMemberPriceList(queryData).then(json => {
                const res = json.data.data.result;

                res.data.forEach(item => {
                    item.buyerLevelPriceList.forEach(priceItem => {
                        if (priceItem.price && item.costPrice) {
                            priceItem.price = priceItem.price - item.costPrice;
                        }
                    });
                });
                this.tableData = res.data;
                this.pagination = res.pagination;

                this.levelList = json.data.data.buyerLevelList;

                return Promise.resolve({ data: res });
            });
        },
        saveCostPrice(row) {
            this.$api.Gd.SkuFreightCost.save({ ids: row.id, costPrice: row.costPrice }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                row.buyerLevelPriceList.forEach(item => {
                    this.saveBuyerPrice(row, item.buyerLevelId, item.price);
                });
            });
        },
        saveBuyerPrice(row, levelId, price) {
            if (!row.costPrice) {
                this.$message({
                    message: '未设置成本价',
                    type: 'error',
                });
                return;
            }
            this.$api.Gd.SkuMemberPrice.saveSkuMemberPriceItem({
                skuId: row.id,
                buyerLevelId: levelId,
                price: price + row.costPrice,
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
            });
        },
        // 批量设置价格
        onBatchSetPrice(level) {
            this.setLevelId = level.id;
            this.isSetBatchPrice = true;
        },
        onConfirmBatchSetPrice() {
            this.tableData.forEach(sku => {
                sku.buyerLevelPriceList.forEach(price => {
                    if (price.buyerLevelId === this.setLevelId) {
                        price.price = this.batchPrice;
                    }
                });
            });
            this.isSetBatchPrice = false;
        },
        onCancelBatchSetPrice() {
            this.isSetBatchPrice = false;
        },
        initSupplierList() {
            this.$api.Gd.Supplier.select({ goodsSendModeList: this.queryFormModel.goodsSendModeList }).then(json => {
                this.supplierList = json.data.data;
            });
        },
    },
    created() {
        if (this.$route.name === 'gd-goods-buyerPrice') {
            this.queryFormModel.goodsSendModeList = '1,2,3';
        } else {
            this.queryFormModel.goodsSendModeList = '4';
        }
        this.initSupplierList();
    },
};
</script>

<style lang="scss">
</style>
